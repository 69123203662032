@media (max-width:390px) {
    .custom_img_1, .custom_img_border {
        width: 320px !important;
    }

    .custom_img_2, .custom_img_border_1 {
        width: 320px !important;
        height: 140px !important;
    }

    .st-pencil {
        margin-top: -25px;
    }
}

.requiredMsg {
    color: red;
    float: left;
}

.toast-container.top-0 {
    top: 10px !important;
}

.toast {
    position: fixed !important;
    z-index: 10 !important;
    top: 15px;
    right: 15px;
}

.toast-body {
    /* padding: 15px; */
    text-align: center;
}

.loader-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    z-index: 99999;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-gif {
    width: 50px;
    height: 50px;
}

.logout-btn {
    cursor: pointer;
}

.mdl-head {
    width: 100%;
    height: 43px;
    text-align: left;
    font: normal normal 600 30px/46px Poppins;
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
}

.mdl-body {
    padding: 40px 0px 10px 0px;
}

.mdl-round {
    border-radius: 20px;
    margin-bottom: 5px;
}

.mdl-img-lbl {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.mdl-img-txt {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.custom-file-button {
    margin-bottom: 10px;
}

#panorama {
    width: 100%;
    height: 100%;
}

.tv-name {
    position: absolute;
    z-index: 1;
    top: 25px;
    color: #FFFFFF;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    text-shadow: 1px 1px 2px #00000099;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.tv-name-bottom {
    position: absolute;
    z-index: 1;
    bottom: 20px;
    color: #FFFFFF;
    font-size: 15px;
    text-align: right;
    justify-content: space-between;
    text-shadow: 1px 1px 2px #00000099;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.tv-list {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 5%;
    margin-left: 15px;
}

.tv-share {
    background: #eee;
    padding: 10px;
    border-radius: 20px;
    display: block;
}

.viewswitch {
    margin-bottom: 10px;
    cursor: pointer;
    height: 40px;
    width: 130px;
    border-radius: 32px;
}

.st-exterior {
    background-image: url(assets/icons/exterior2.png);
    display: inline-block;
    background-size: cover;
    background-color: transparent;
}

.st-interior {
    background-image: url(assets/icons/interior2.png);
    display: inline-block;
    background-size: cover;
    background-color: transparent;
}

.lvlname {
    color: white;
    cursor: pointer;
    border-radius: 32px;
    background: rgb(39 39 39 / 51%);
    margin-bottom: 20px;
    cursor: pointer;
    height: 40px;
    width: 130px;
    line-height: 40px;
    float: none;
}

.lvlshow {
    border: 1px solid white;
}

.nav_custom {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 3px 15px #00000029;*/
    opacity: 1;
    padding: 1.5rem;
    padding-bottom: 0;
}

.fontawetourdet {
    color: #3A6DFF;
    width: 25px;
}

.mxwidth {
    max-width: 100px;
}

.st-pencil {
    cursor: pointer;
    height: 12px;
    width: 12px;
    display: inline-block;
    background-size: contain;
    float: right;
    margin-top: 5px;
}

.st-pencil-grey {
    background-image: url(assets/icons/editgrey.png);
    background-repeat: no-repeat;
}

.st-pencil-white {
    background-image: url(assets/icons/editwhite.png);
    background-repeat: no-repeat;
}

.st-trash {
    cursor: pointer;
    height: 12px;
    width: 12px;
    display: inline-block;
    background-size: contain;
    float: right;
    margin-top: 5px;
}

.st-trash-grey {
    background-image: url(assets/icons/deletegray.png);
    background-repeat: no-repeat;
}

.st-trash-white {
    background-image: url(assets/icons/deletewhite.png);
    background-repeat: no-repeat;
}

.st-pencil-trash {
    z-index: 1;
}

.st-pin {
    background-image: url(assets/icons/pin.png);
    background-repeat: no-repeat;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: inline-block;
    background-size: contain;
}

.st-cash {
    background-image: url(assets/icons/cash-money.png);
    background-repeat: no-repeat;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: inline-block;
    background-size: contain;
}

.st-flats {
    background-image: url(assets/icons/flats.png);
    background-repeat: no-repeat;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: inline-block;
    background-size: contain;
}

.st-resize {
    background-image: url(assets/icons/resize.png);
    background-repeat: no-repeat;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: inline-block;
    background-size: contain;
}

.st-info {
    background-image: url(assets/icons/info.png);
    background-repeat: no-repeat;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-size: contain;
    display: inline-block;
    /*  margin-right: auto;
    position: absolute;
    right: 30px;
    top: 5px;*/
}

input[type=file]:before {
    content: "Select Image";
    position: absolute;
    z-index: 2;
    display: block;
    background-color: white;
    width: 92px;
    height: 29px;
    cursor: pointer;
}

.toast-container {
    z-index: 999999;
}

#context-menu {
    position: fixed;
    z-index: 10000;
    width: 150px;
    display: none;
}

    #context-menu.visible {
        display: block;
    }

.mdl-dest {
    height: 120px;
    text-align: left;
    white-space: nowrap;
    overflow-y: hidden;
}

.pnlm-customtool {
    visibility: visible !important;
    position: fixed !important;
    border: 1px solid #ccc;
    margin-top: 20px;
}

.btn-hotspot {
    background-color: white !important;
}

.btn-ctxmen {
    margin-bottom: 0px;
    width: 100px;
    font-size: 10px;
    height: 20px;
    padding-top: 2px !important;
}

    .btn-ctxmen:hover {
        background-color: #eee !important;
    }

div.custom-tooltip span, div.custom-tooltip-div span {
    visibility: hidden;
    position: absolute;
    border-radius: 3px;
    background-color: #fff;
    color: #000;
    text-align: center;
    max-width: 200px;
    padding: 5px 10px;
    margin-left: -220px;
    cursor: pointer;
}

div.custom-tooltip:hover span, .custom-tooltip-div-show {
    visibility: visible !important;
}

    div.custom-tooltip:hover span:after, .custom-tooltip-div-show:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-width: 10px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
        bottom: -20px;
        left: -10px;
        margin: 0 50%;
    }

.editarrow {
    background: #26A9FF;
    color: white;
    font-size: 10px;
    border-radius: 20px;
    cursor: pointer;
    z-index: 9999999 !important;
    width: 50px;
    display: inline-block;
}

    .editarrow:hover {
        background: #0069d9;
    }

span.pnlm-about-msg {
    display: none !important;
}

.sltfloor {
    width: 150px;
    float: right;
    margin-top: -40px;
    padding: 0.5rem 1rem !important;
}

.slttour {
    padding: 0.5rem 1rem !important;
}

.htasset {
    cursor: pointer !important;
}

    .htasset::before {
        content: url('assets/images/asset.png');
    }

.htslider1::before {
    content: url('assets/images/arrow/Arrow1.png');
}

.htslider2::before {
    content: url('assets/images/arrow/Arrow2.png');
}

.htslider3::before {
    content: url('assets/images/arrow/Arrow3.png');
}

.htslider4::before {
    content: url('assets/images/arrow/Arrow4.png');
}

.htslider5::before {
    content: url('assets/images/arrow/Arrow5.png');
}

.htslider6::before {
    content: url('assets/images/arrow/Arrow6.png');
}

.htslider7::before {
    content: url('assets/images/arrow/Arrow7.png');
}

.htslider8::before {
    content: url('assets/images/arrow/Arrow8.png');
}

.tourmodalbody label {
    font-weight: 500 !important;
}

.react-tagsinput {
    background-color: rgba(242, 242, 242, 1) !important;
    margin-bottom: 10px;
    border: none !important;
}

.react-tagsinput-input {
    width: 110px !important;
}

.stlink {
    cursor: pointer;
}

.stlinkbtn {
    width: 150px;
    display: block;
    margin: 0 auto;
}

.custom_img_border_span_level {
    padding-left: 20px;
    padding-right: 20px;
}

.custom_img_border_span_level1 {
    padding-top: 10px;
    position: fixed;
    width: 30px;
    padding-right: 5px;
}

.navbarSt a.dropdown-item {
    width: 0;
    background: none;
    line-height: 0;
    display: block;
    height: inherit;
    margin: 0;
    padding: 0.35rem 1.5rem;
}

.navbarSt .dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    left: auto !important;
    right: 0 !important;
}

.mb-1 {
    font-size: 13px;
    font-weight: 500;
}

.interiorerrortxt {
    text-align: center;
    padding-bottom: 50px;
    font-weight: bold;
    font-size: 25px;
}

.circle-306 {
    box-sizing: border-box;
    border-radius: 50%;
    border-width: 1.5px;
    border-style: solid;
    border-color: rgb(0, 120, 212) rgb(199, 224, 244) rgb(199, 224, 244);
    border-image: initial;
    animation-name: css-300;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
    width: 28px;
    height: 28px;
}

.tv_mdlbdy {
    padding: 0 !important;
}

.paypal-button-number-1 {
    display: none;
}

#feedbackBtn {
    display: block;
    position: fixed;
    bottom: 60px;
    left: 60px;
    z-index: 99;
    color: #8E8E8E;
    background-color: rgba(242, 242, 242, 1);
    border-color: rgba(242, 242, 242, 1);
    box-shadow: 1px 3px 8px #00000029;
}

.mt5 {
    margin-top: 120px;
}

.showdv {
    display: block;
}

.hiddendv {
    display: none;
}

.showdvi {
    display: inline-block;
}

.adminmenu > .col-lg-12 > .active {
    background-color: #eee;
    border-radius: 10px;
}
